import { Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appContiditionPageContent]'
})
export class ContiditionPageContentDirective {

  constructor(
    public templateRef: TemplateRef<any>
  ) { }

}
