import { Environment } from '@app-environments/environment';
import { RouterState } from '@app/core/route-serializer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';

export interface State {
  routes: RouterReducerState<RouterState>;
}

export const reducers: ActionReducerMap<State> = {
  routes: routerReducer
};

export const metaReducers: Array<MetaReducer<State>> = !Environment.production
  ? []
  : [];
