import { MarkdownModule, MarkedOptions } from 'ngx-markdown';

import { MediaMatcher } from '@angular/cdk/layout';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { Environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { markedOptionsFactory } from './core/markdown-options';
import { CustomRouteSerializer } from './core/route-serializer';
import { AuthGuardService } from './core/services/auth-guard';
import { BaseHttpInterceptor } from './core/services/base-http.interceptor';
import { RoleGuardService } from './core/services/role.guard';
import { metaReducers, reducers } from './reducers';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory
      }
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    !Environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouteSerializer
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: Environment.production
    })
  ],
  providers: [
    AuthGuardService,
    RoleGuardService,
    MediaMatcher,
    { provide: HTTP_INTERCEPTORS, useClass: BaseHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
