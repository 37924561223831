/*
This shim exists because importing the AppInsightsService in any ts file that is used for SSR
will cause the define is not defined error. To get around this, SSR and browser will have different
version of this class, one which imports that service and uses is, and one which does nothing.
*/

export abstract class AppInsightsServiceShim {
  public abstract setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string): void;
  public abstract clearAuthenticatedUserContext(): void;

  public abstract trackEvent(
    name: string,
    properties?: { [name: string]: string; },
    measurements?: { [name: string]: number; }
  ): void;
}
