import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppServerAuthService } from '@app/core/services/app-server-auth.service';

@Injectable()
export class BaseHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AppServerAuthService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    const headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    req = req.clone({ headers });

    //Handle CORB issues
    const output = next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && error.url.indexOf('api/userinfo') === -1) {
            this.authService.getUserInfo(true);
          } else if (error.status === 0 && typeof window !== 'undefined' && window.navigator.onLine) {
            this.authService.login();
            throw new Error('Redirecting to login page');
          }
        }

        throw error;
      })
    );

    return output;
  }
}
