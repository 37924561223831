
import {
  AppInsightsService, ApplicationInsightsModule
} from '@markpieszak/ng-application-insights';

import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { AppInsightsServiceShim } from './core/services/app-insights.shim';
import { BrowserAppInsightsShim } from './core/services/app-insights.shim.browser';

@NgModule({
  imports: [
    // The AppBrowseerModule should import your AppModule followed
    // by any browser only modules
    AppModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKeySetLater: true
    })
  ],
  providers: [
    AppInsightsService,
    { provide: AppInsightsServiceShim, useClass: BrowserAppInsightsShim }
  ],
  // Since the bootstrapped component is not inherited from your
  // imported AppModule, it needs to be repeated here.
  bootstrap: [AppComponent],
})
export class AppBrowserModule {
  constructor(appInsights: AppInsightsService) {
    // actually config the instramentation key for AOT
    appInsights.config = {
      instrumentationKey: (window['Environment'] || {}).Application_Insights_Id,
      instrumentationKeySetLater: false
    };
    appInsights.init();
  }
}
