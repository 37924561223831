import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  NavigationExtras,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { AppServerAuthService } from './app-server-auth.service';

@Injectable()
export class RoleGuardService
  implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private authService: AppServerAuthService,
    private router: Router
  ) {}

  private checkRole(route: Route | ActivatedRouteSnapshot): Promise<boolean> {
    const roles = (route.data ? route.data.roles : null) || [];
    const noRedirect = (route.data ? route.data.noRedirect : false) || false;
    const result = this.authService.hasSomeRole(roles);

    if (!result && !noRedirect) {
      this.router.navigate(['forbidden']);
    }
    return Promise.resolve(result);
  }

  // Permits the asynchronous loading of a given url
  canLoad(route: Route): Promise<boolean> {
    return this.checkRole(route);
  }

  // Assuming module/component loaded, checks if route can be activated
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.checkRole(route);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.checkRole(route);
  }
}
