/* This is the DSL animations for PageComponent.  */

import {
  animate,
  animateChild,
  group,
  query,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

// These were not working with AOT, so I inlined all of them...  :/
// RIP maintainability (for now...)
// export const contentMobileState = {
//   position: 'absolute',
//   top: 0,
//   bottom: 0,
//   right: 0,
//   left: 0,
//   opacity: 1,
//   zIndex: 0
// };

// export const rightSidebarStyles = {
//   position: 'absolute',
//   top: 0,
//   right: 0,
//   bottom: 0,
//   zIndex: 1,
//   width: '300px',
//   display: 'flex'
// };

export const PageAnimations = [
  trigger('hostSink', [transition(':leave', [animate('100ms')])]),
  trigger('childrenMobile', [
    state('top', style({ opacity: 1 })),
    state('below', style({ opacity: 0 })),

    transition('below => top', [animate('250ms cubic-bezier(0, 0, 0.2, 1)')]),
    transition('top => below', [animate('200ms cubic-bezier(0.4, 0, 1, 1)')]),
    //handle how right sidebars on sm interact with this state
    transition('right => below', [
      style({ opacity: 1 }),
      animate('250ms cubic-bezier(0.4, 0, 1, 1)', style({ opacity: 1 }))
    ])
  ]),
  trigger('contentMobile', [
    state('top', style({ opacity: 1 })),
    state('below', style({ opacity: 1 })),

    transition('top => void, top => below', [
      style({
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        opacity: 1,
        zIndex: 0
      }),
      animate(
        '250ms cubic-bezier(0, 0, 0.2, 1)',
        style({
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          zIndex: 0,
          opacity: 0
        })
      )
    ]),
    transition('void => top, below => top', [
      style({
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 0,
        opacity: 0
      }),
      animate(
        '200ms cubic-bezier(0.4, 0, 1, 1)',
        style({
          position: 'absolute',
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          opacity: 1,
          zIndex: 0
        })
      )
    ]),
    transition('null => below, below => void', style({ display: 'none' }))
  ]),
  trigger('bodyRight', [
    state('show', style({ marginRight: '300px' })),
    state('hide', style({ marginRight: 0 })),

    transition('show => hide', [
      group([
        query('@childrenRight, @childrenMobile', [animateChild()], {
          optional: true
        }),
        animate('250ms cubic-bezier(0.4, 0, 1, 1)')
      ])
    ]),
    transition('hide => show', [
      group([
        query('@childrenRight', [animateChild()], { optional: true }),
        animate('350ms cubic-bezier(0, 0, 0.2, 1)')
      ])
    ])
  ]),
  trigger('childrenRight', [
    state(
      'show',
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        width: '300px',
        display: 'flex',
        transform: 'none'
      })
    ),

    transition('show => hide', [
      animate(
        '250ms cubic-bezier(0.4, 0, 1, 1)',
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          width: '300px',
          display: 'flex',
          transform: 'translateX(301px)'
        })
      )
    ]),
    transition('hide => show', [
      style({
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        width: '300px',
        display: 'flex',
        transform: 'translateX(301px)'
      }),
      animate('350ms cubic-bezier(0, 0, 0.2, 1)')
    ])
  ])
];
