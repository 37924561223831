import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

import { AppServerAuthService } from '../../services/app-server-auth.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {

  constructor(private authService: AppServerAuthService) {
  }

  login() {
   this.authService.login();
  }
}
