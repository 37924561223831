import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  (renderer as any).super_link = renderer.link;
  renderer.link = function(href: string, title: string, text: string): string {
    const link = (this as any).super_link(href, title, text);

    if (!href.startsWith('/')) {
      const newElement = document.createElement('div');
      newElement.innerHTML = link;
      newElement.querySelector('a').target = '_blank';
      return newElement.innerHTML;
    }

    return link;
  };

  return {
    renderer,
    gfm: true,
    // tables: true,
    breaks: false,
    pedantic: false,
    // sanitize: false,
    smartLists: true,
    smartypants: false,
  };
}
