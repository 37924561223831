import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

import { AnimationBuilder, AnimationPlayer } from '@angular/animations';
import {
  AfterViewInit, Attribute, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild,
  ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, SkipSelf, ViewChild
} from '@angular/core';
import {
  ContiditionPageContentDirective
} from '@app/common/shared/directives/contidition-page-content.directive';
import { BreakpointService } from '@app/core/services/breakpoint.service';

import { buildLeftBodyAnimations, buildLeftContentAnimations } from './left.animations';
import { PageAnimations } from './page.animations';
import {
  computeBreakpointState, computeCoreState, CoreState, Role, SizeDependentState
} from './page.models';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    ...PageAnimations
  ]
})
export class PageComponent implements OnDestroy, AfterViewInit {

  @ViewChild('body', { static: true })
  public bodyRef: ElementRef;
  @ViewChild('content', { static: true })
  public contentRef: ElementRef;
  @ViewChild('children', { static: true })
  public childrenRef: ElementRef;

  @HostBinding('@hostSink')
  public readonly hostSink = true;

  private readonly destroySignal$ = new Subject<void>();

  private _isDestroyed = false;
  private _coreState: CoreState;
  private _breakpointState: SizeDependentState;

  private bodyAnimation: AnimationPlayer;
  private contentAnimation: AnimationPlayer;

  public get coreState(): CoreState {
    return this._coreState;
  }

  public get breakpointState(): SizeDependentState {
    return this._breakpointState;
  }

  public get isDestroyed(): boolean {
    return this._isDestroyed;
  }

  @ContentChild(ContiditionPageContentDirective)
  public conditionalChild: ContiditionPageContentDirective;

  private readonly children$ = new BehaviorSubject<PageComponent[]>([]);
  public readonly hasChildren$: Observable<boolean>
    = this.children$.pipe(
      map((c) => c ? !!c.length : false),
      distinctUntilChanged()
    );

  private readonly childCoreState$: Observable<CoreState[]>
    = this.children$.pipe(
      switchMap((children) =>
        children.length ?
          combineLatest(children.map((child) => child.coreState$)) :
          of([])
      ),
      shareReplay(1)
    );

  public readonly coreState$: Observable<CoreState> =
    this.childCoreState$.pipe(
      map((childStates) => computeCoreState(childStates, {
        isBehind: !!this.isBehind,
        pullRight: !!this.pullRight
      })),
      shareReplay(1)
    );

  public readonly contentRole$: Observable<Role> = this.coreState$.pipe(map((cState) => cState.role));
  public readonly isPrimary$: Observable<boolean>;

  public readonly sizeDependentState$: Observable<SizeDependentState>;

  constructor(
    @Optional() @SkipSelf() private parent: PageComponent,
    @Attribute('pullRight') private pullRight: string,
    @Attribute('isBehind') private isBehind: string,
    @Attribute('debugKey') private debugKey: string,
    private breakpoint: BreakpointService,
    private changeDetector: ChangeDetectorRef,
    private builder: AnimationBuilder
  ) {

    this.sizeDependentState$ = combineLatest(
      this.breakpoint.displayMode$,
      this.coreState$
    ).pipe(
      map(([media, coreState]) => computeBreakpointState(debugKey, media, coreState)),
      shareReplay(1)
    );

    this.isPrimary$ = this.sizeDependentState$.pipe(map((state) => state.isPrimary));

    this.coreState$.pipe(
      takeUntil(this.destroySignal$)
    ).subscribe((cState) => {
      this._coreState = cState;
    });

    this.sizeDependentState$.pipe(
      takeUntil(this.destroySignal$)
    ).subscribe((bState) => {
      this.runLeftAnimations(bState);
      this._breakpointState = bState;

      if (!this._isDestroyed) {
        this.changeDetector.markForCheck();
      }
    });

    // this has to happen in the constructor because we need the relationship
    // tree to be built before the DOM is constructed
    if (this.parent) {
      this.parent.registerChild(this);
    }
  }

  ngAfterViewInit() {
    this.runLeftAnimations(this.breakpointState, true);
  }

  ngOnDestroy() {
    if (this.parent) {
      this.parent.unregisterChild(this);
    }
    this.destroySignal$.next();
    this.destroySignal$.complete();
    this._isDestroyed = true;

    if (this.bodyAnimation) {
      this.bodyAnimation.destroy();
    }
    if (this.contentAnimation) {
      this.contentAnimation.destroy();
    }
  }

  private registerChild(child: PageComponent): void {
    this.children$.next([...this.children$.value, child]);
  }

  private unregisterChild(child: PageComponent): void {
    this.children$.next(this.children$.value.filter((c) => c !== child));
  }

  private runLeftAnimations(newState: SizeDependentState, isFirstRun: boolean = false): void {
    const body = buildLeftBodyAnimations(this.builder, this.bodyRef, this.breakpointState, newState, isFirstRun);
    const content = buildLeftContentAnimations(
      this.builder, this.contentRef, this.breakpointState, newState, isFirstRun);

    if (body) {
      if (this.bodyAnimation) {
        this.bodyAnimation.destroy();
      }
      this.bodyAnimation = body;

      this.bodyRef.nativeElement.classList.add('app-animating');
      body.onDone(() => this.bodyRef.nativeElement.classList.remove('app-animating'));
      body.play();
    }
    if (content) {
      if (this.contentAnimation) {
        this.contentAnimation.destroy();
      }
      this.contentAnimation = content;
      content.play();
    }
  }

}
