import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { Environment } from "./environments/environment";
import { AppBrowserModule } from './app/app.browser.module';


// Initialize environment variables
if (typeof window !== 'undefined') {
  Environment.init(window["Environment"]);
}

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}

const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];

if (Environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers)
  .bootstrapModule(AppBrowserModule)
  .catch(err => console.error(err));
