<span class="dow--red-gray">
  <mat-progress-bar *ngIf="isLoading"
    color="accent"
    class="loading-bar"
    mode="indeterminate"
  ></mat-progress-bar>
</span>

<div class="error-wrapper" [@slideIn]="hasError">
  <!-- <div class="error-display" *ngIf="error" @leaveSink>
    <h3 class="mat-subtitle" i18n>{error, select,
      offline {Network error}
      other {Something went wrong}
    }</h3>
    <p class="mat-body" i18n>{error, select,
      offline {Please check your network connection and try again.}
      other {An unknown error occurred. Please try again.}
    }</p>
  </div> -->
  <ng-container *ngIf="hasError">
    <ng-content select="app-error-display"></ng-content>
  </ng-container>
</div>

<div class="content">
  <ng-content></ng-content>
</div>
