<mat-toolbar class="dmc-header">
  <h1 i18n>Service Offering Framework</h1>
</mat-toolbar>

<div class="card-container">
  <mat-card class="logged-out-card">
    <mat-card-title i18n>Nothing to see here</mat-card-title>
    <mat-card-content i18n>The page your are trying to reach could not be found.  Please try a different address or click the home button below.</mat-card-content>
    <mat-card-content fxLayoutAlign="end end">
      <a mat-button color="primary" routerLink="/" i18n>Home</a>
    </mat-card-content>
  </mat-card>
</div>
