import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

import { AppServerAuthService } from '../../services/app-server-auth.service';

@Component({
    selector: 'app-loggedout',
    templateUrl: './loggedout.component.html',
    styleUrls: ['loggedout.component.scss']
})
export class LoggedOutComponent {

    constructor(private authService: AppServerAuthService) { }

    login() {
        this.authService.login(false);
    }
}
