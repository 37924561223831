import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Environment } from '@app-environments/environment';

import { ForbiddenComponent } from './core/containers/forbidden/forbidden.component';
import { LoggedOutComponent } from './core/containers/loggedout/loggedout.component';
import { NotFoundComponent } from './core/containers/not-found/not-found.component';
import { AuthGuardService } from './core/services/auth-guard';
import { RoleGuardService } from './core/services/role.guard';

const appRoutes: Routes = [
  { path: '', redirectTo: 'functions', pathMatch: 'full' },
  {
    path: 'functions',
    loadChildren: () => import('./functions/functions.module').then((m) => m.FunctionsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: ['Admin'] }
  },
  { path: 'loggedout', component: LoggedOutComponent },
  { path: 'forbidden', component: ForbiddenComponent },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: !Environment.production, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
 // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
