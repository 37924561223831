import { transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Output } from '@angular/core';

@Component({
  selector: 'app-error-display',
  templateUrl: './error-display.component.html',
  styleUrls: ['./error-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('leaveSink', [
      transition(':leave', [])
    ])
  ]
})
export class ErrorDisplayComponent {

  @HostBinding('@leaveSink')
  public readonly leaveSink = true;

  @Output()
  public readonly tryAgain = new EventEmitter<string>();

}
