import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState } from '../core/route-serializer';

export const routerSelector = createFeatureSelector<
  RouterReducerState<RouterState>
>('routes');
export const routerState = createSelector(
  routerSelector,
  (r) => (r ? r.state : undefined)
);

export const routeParameters = createSelector(
  routerState,
  (r) => (r ? r.params : {})
);
