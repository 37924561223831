<div class="beta-warning mat-elevation-z1" *ngIf="isBeta">
  <p>
    <span class="mat-body-2">WARNING</span> this is a BETA site! SOF and
    customer distinction information may not be up to date. Please refer to
    <a href="https://cxsof.dow.com">https://cxsof.dow.com</a> for the most up to
    date SOF information.
  </p>
</div>

<div id="router-loading" *ngIf="($isLoading | async)">
  <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
</div>

<router-outlet></router-outlet>
