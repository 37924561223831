import { InjectionToken } from '@angular/core';

export * from './reducers';
export * from './route';

export const USE_MOCK_DATA = new InjectionToken<boolean>('app.useMockData', {
  providedIn: 'root',
  // TODO: should this be an app setting?
  factory: () => false
});
