import { A11yModule } from '@angular/cdk/a11y';
import { PlatformModule } from '@angular/cdk/platform';
import { CdkScrollableModule, ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DmcDialogModule } from '@dmc/ng-components/dialog';

import { RouterModule } from '@angular/router';
import {
  ConfidentialityDisclaimerComponent
  //This import line is too long for one import and prettier hates it
} from './components/confidentiality-disclaimer/confidentiality-disclaimer.component';
import { DataDisplayComponent } from './components/data-display/data-display.component';
import { DataListComponent } from './components/data-list/data-list.component';
import { ErrorBodyComponent } from './components/error-body/error-body.component';
import { ErrorDisplayComponent } from './components/error-display/error-display.component';
import { ErrorTitleComponent } from './components/error-title/error-title.component';
import { HeaderActionsComponent } from './components/header-actions/header-actions.component';
import { LoadingDisplayComponent } from './components/loading-display/loading-display.component';
import { PageComponent } from './components/page/page.component';
import { ContiditionPageContentDirective } from './directives/contidition-page-content.directive';

const MATERIALCOMPONENTS = [
  CdkTableModule,
  PlatformModule,
  CdkScrollableModule,
  ScrollingModule,
  A11yModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
];

@NgModule({
  declarations: [
    HeaderActionsComponent,
    ConfidentialityDisclaimerComponent,
    ContiditionPageContentDirective,
    DataDisplayComponent,
    DataListComponent,
    ErrorBodyComponent,
    ErrorDisplayComponent,
    ErrorTitleComponent,
    LoadingDisplayComponent,
    PageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FlexModule,
    MATERIALCOMPONENTS,
    DmcDialogModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexModule,
    MATERIALCOMPONENTS,
    HeaderActionsComponent,
    ConfidentialityDisclaimerComponent,
    ContiditionPageContentDirective,
    DataDisplayComponent,
    DataListComponent,
    ErrorBodyComponent,
    ErrorDisplayComponent,
    ErrorTitleComponent,
    LoadingDisplayComponent,
    PageComponent,
    DmcDialogModule,
  ]
})
export class SharedModule {}
