import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { AppServerAuthService } from '@app/core/services/app-server-auth.service';

@Component({
  selector: 'app-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderActionsComponent implements OnInit {

  public displayName: string;

  constructor(public authService: AppServerAuthService) { }

  ngOnInit() {
    this.displayName = this.authService.getDisplayName();
  }

  logout() {
    this.authService.logout();
  }

  login() {
    this.authService.login();
  }

}
