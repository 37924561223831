import { empty, merge, Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  scan,
  startWith,
  tap
} from 'rxjs/operators';

import { Platform } from '@angular/cdk/platform';
import { ChangeDetectorRef, Component, HostBinding } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { Environment } from '@app-environments/environment';

import { AppServerAuthService } from './core/services/app-server-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public $isLoading: Observable<boolean>;
  public $showCookieNotifier: Observable<boolean>;

  @HostBinding('class.ie')
  public get classIE(): boolean {
    return this.platform.TRIDENT;
  }
  @HostBinding('class.safari')
  public get classSafari(): boolean {
    return this.platform.SAFARI || this.platform.IOS;
  }

  public get isBeta(): boolean {
    return Environment.Show_Beta_Warning;
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    public authService: AppServerAuthService,
    private router: Router,
    private platform: Platform
  ) {
    this.$isLoading = this.router.events.pipe(
      mergeMap((event: Event) => {
        switch (true) {
          case event instanceof NavigationStart:
            return of(true);

          case event instanceof NavigationEnd:
          case event instanceof NavigationCancel:
          case event instanceof NavigationError:
            return of(false);

          default:
            return empty();
        }
      })
    );
  }
}
