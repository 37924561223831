import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-data-display',
  templateUrl: './data-display.component.html',
  styleUrls: ['./data-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideIn', [
      state('true', style({height: '*', padding: '*', display: 'block'})),
      state('false', style({height: 0, padding: 0, display: 'none'})),

      transition('false => true', [
        style({display: 'block'}),
        animate('250ms cubic-bezier(0, 0, 0.2, 1)')
      ]),
      transition('true => false', [
        animate('200ms cubic-bezier(0.4, 0, 1, 1)', style({display: 'block'}))
      ])
    ]),
    trigger('leaveSink', [
      transition(':leave', [])
    ])
  ]
})
export class DataDisplayComponent {

  @Input()
  public isLoading: boolean;
  @Input()
  public hasError: boolean;
  @Input()
  public hasContent: string;

  @Input()
  public bodyClasses: string[];

  public get bodyClasses_obj(): any {
    return (this.bodyClasses || []).reduce((acc, c) => acc[c] = true, {});
  }

}
