<span fxFlex>ServiceOfferingFramework.Client</span>
<span class="user-button">
  <button mat-button [matMenuTriggerFor]="menu" class="large-user-account">
    {{ displayName || "Unknown User" }}
    <i class="material-icons">&#xE5C5;</i>
  </button>
  <button mat-icon-button [matMenuTriggerFor]="menu" class="small-user-account">
    <div class="icon">
      <i class="material-icons">&#xE853;</i>
    </div>
  </button>
  <mat-menu #menu="matMenu">
      <span mat-menu-item disabled class="user-name">{{ displayName }}</span>
      <a *ngIf="authService.isLoggedIn()" mat-menu-item (click)="logout()">Logout</a>
      <a *ngIf="!authService.isLoggedIn()" mat-menu-item (click)="login()">Login</a>
  </mat-menu>
</span>
