<button mat-button [matMenuTriggerFor]="menu" class="large-user-account">
  <mat-icon>account_circle</mat-icon>
  {{ displayName || 'Unknown User' }}
  <!-- <mat-icon>arrow_drop_down</mat-icon> -->
</button>
<button mat-icon-button [matMenuTriggerFor]="menu" class="small-user-account">
  <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #menu="matMenu" xPosition="before">
  <span mat-menu-item disableRipple class="user-name">{{ displayName }}</span>
  <a *ngIf="authService.isLoggedIn()" mat-menu-item (click)="logout()"
    >Logout</a
  >
  <a *ngIf="!authService.isLoggedIn()" mat-menu-item (click)="login()">Login</a>
</mat-menu>
