import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';

import { UserClaims } from '../models/user-claims.model';
import { AppInsightsServiceShim } from './app-insights.shim';

/**
 * Local App Server Proxy Authentication.
 * Assumes that hosting app server will function as a both the token issuer endpoint and identity provider
 * Assumes Login/Logout are at Account/Login and Account/Logout
 *
 * @export
 * @class AppServerAuthService
 */
@Injectable()
export class AppServerAuthService {
  public currentUser: UserClaims | undefined;
  private baseUrl: string;
  private appInsights: AppInsightsServiceShim; //AppInsightsService
  // Should use a type import, but doing so blows things up with SSR

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    @Inject(PLATFORM_ID) private platformId,
    private injector: Injector
  ) {
    this.baseUrl = baseUrl;
    if (isPlatformBrowser(platformId)) {
      this.appInsights = this.injector.get(AppInsightsServiceShim);
    }
  }

  /**
   * Checks policies to determine if user is online
   *
   * @returns {boolean}
   * @memberof AdalAuthService
   */
  public isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  public logout(): void {
    const logoutUrl = `/Account/Logout`;
    this.currentUser = null;
    if (typeof window !== 'undefined') {
      window.location.href = logoutUrl;
    }
  }

  public get isRole_Admin(): boolean {
    return (
      this.currentUser &&
      this.currentUser.role &&
      this.currentUser.role.includes('Admin')
    );
  }

  public hasSomeRole(roles: string[]): boolean {
    return this.currentUser.role.some((r) => roles.includes(r));
  }

  /**
   * Gets a display-friendly name for the signed in user
   * @return {string} Display friendly name of current user
   */
  public getDisplayName(): string {
    if (
      this.currentUser &&
      this.currentUser.given_name &&
      this.currentUser.surname
    ) {
      return this.currentUser.given_name.concat(' ', this.currentUser.surname);
    } else {
      return this.currentUser.name;
    }
  }

  /**
   * Retrieves a valid access token from the active session policy
   *
   * @returns {Promise<string>}
   * @memberof AppServerAuthService
   */
  public getAccessToken(): Promise<string> {
    return Promise.resolve(null);
  }

  /**
   * Maps raw list of claims in a new UserClaims object
   *
   * @private
   * @returns {UserClaims}
   * @memberof AppServerAuthService
   */
  private getUserClaims(rawClaims: {}): UserClaims {
    const claimObj = {};
    Object.keys(rawClaims).forEach((key) => {
      const obj = rawClaims[key];
      const type = this.getFriendlyClaimType(obj.type);
      if (type === 'role') {
        // role should only come back once, but...
        claimObj['role'] = [...(claimObj['role'] || []), obj.value];
      } else {
        claimObj[type] = obj.value;
      }
    });

    const claims = new UserClaims({
      name: claimObj['name'],
      given_name: claimObj['givenname'],
      surname: claimObj['surname'],
      emails: claimObj['emails'] || claimObj['unique_name'],
      streetAddress: claimObj['streetAddress'],
      city: claimObj['city'],
      postalCode: claimObj['postalCode'],
      state: claimObj['state'],
      country: claimObj['country'],
      jobTitle: claimObj['jobTitle'],
      oid: claimObj['oid'] || claimObj['objectidentifier'],
      newUser: claimObj['newUser'],
      email:
        claimObj['email'] ||
        claimObj['unique_name'] ||
        claimObj['emailaddress'] ||
        claimObj['xemail'],
      preferred_username:
        claimObj['preferred_username'] || claimObj['unique_name'],
      in_corp: claimObj['in_corp'] || false,
      role: claimObj['role'] || []
    });

    return claims;
  }

  /**
   * Returns the meaningful part of the claimType.
   * 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress' would become emailaddress
   *
   * @private
   * @param {string} fullClaimType
   * @returns
   * @memberof AppServerAuthService
   */
  private getFriendlyClaimType(fullClaimType: string) {
    return fullClaimType
      .substr(fullClaimType.lastIndexOf('/') + 1)
      .toLowerCase();
  }

  /**
   * converts list of claims into an object of claimType:value pairs
   *
   * @private
   * @param {any[]} claims
   * @returns
   * @memberof AppServerAuthService
   */
  private parseRawClaims(claims: any[]) {
    const claimObj = {};
    let selectedClaims = [];
    selectedClaims = claims.map((c) => ({
      type: this.getFriendlyClaimType(c.type),
      value: c.value
    }));
    selectedClaims.map((c) => (claimObj[c.type] = c.value));
    return claimObj;
  }

  /**
   * Logs in the user by redirecting to the local Account/Login endpoint.
   *
   * @memberof AppServerAuthService
   */
  public login(includePath: boolean = true) {
    const loginUrl = `/Account/Login`;
    if (typeof window !== 'undefined') {
      const path = window.location.pathname;
      window.location.href = includePath
        ? `${loginUrl}?path=${encodeURIComponent(path)}`
        : loginUrl;
    }
  }

  /**
   * Gets the currently logged in user's information found at the local userinfo endpoint.
   *
   * @returns
   * @memberof AppServerAuthService
   */
  public getUserInfo(isTest?: boolean) {
    const userInfoUrl = `api/userinfo`;
    return this.http
      .get(userInfoUrl)
      .toPromise()
      .then(
        (roles) => {
          const userClaims: UserClaims = this.getUserClaims(roles);
          this.currentUser = userClaims;
          if (this.appInsights) {
            this.appInsights.setAuthenticatedUserContext(userClaims.oid);
          }
        },
        (error) => {
          this.currentUser = null;
          if (this.appInsights) {
            this.appInsights.clearAuthenticatedUserContext();
          }

          if (error.status === 403) {
            this.currentUser = new UserClaims({
              name: 'Unauthorized User'
            });
          }

          if (error.status === 0 || isTest) {
            this.login();
          }
        }
      )
      .catch((error: Response) => {
        this.currentUser = null;
        if (this.appInsights) {
          this.appInsights.clearAuthenticatedUserContext();
        }
      });
  }
}
