import { Params, RouterStateSnapshot } from '@angular/router';
import { BaseRouterStoreState, RouterStateSerializer } from '@ngrx/router-store';

export interface RouterState  extends BaseRouterStoreState {
  params: Params;
  queryParams: Params;
}

export class CustomRouteSerializer implements RouterStateSerializer<RouterState> {
  serialize(routerState: RouterStateSnapshot): RouterState {
    let route = routerState.root;
    let deepestRoute = null;
    let params = {};

    while (route) {
      params = {
        ...params,
        ...route.params
      };

      if (!route.firstChild) {
        deepestRoute = route;
      }

      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;

    return {
      url,
      params,
      queryParams
    };
  }
}
