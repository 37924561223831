export class UserClaims {
  // display name--this will default to 'unknown' if claim exists but wasnt provided by user.
  public name?: string = '';
  // first name
  public given_name?: string = '';
  // last name
  public surname?: string = '';
  public emails?: string[] = [''];
  public streetAddress?: string = '';
  public city?: string = '';
  public postalCode?: string = '';
  public state?: string = '';
  public country?: string = '';
  // used for company in our case
  public jobTitle?: string = '';
  // Object identifier (ID) of the user object in Azure AD.
  public oid?: string = '';
  // if the user just signed up
  public newUser?: boolean = false;
  public email?: string = '';
  public preferred_username: string = '';
  public in_corp: boolean = false;
  //array for now (should likely be just a single string?)
  public role: string[] = [];

  constructor(initObj?: Partial<UserClaims>) {
    if (initObj) {
      Object.keys(initObj).forEach(
        (key) => !initObj[key] && delete initObj[key]
      );
      Object.assign(this, initObj);
    }
  }
}
