import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-error-title',
  templateUrl: './error-title.component.html',
  styleUrls: ['./error-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorTitleComponent {

  @HostBinding('class.mat-title')
  public readonly classBody = true;

}
