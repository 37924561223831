import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-display',
  templateUrl: './loading-display.component.html',
  styleUrls: ['./loading-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
