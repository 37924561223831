import { Injectable } from '@angular/core';
import { AppInsightsService } from '@markpieszak/ng-application-insights';

import { AppInsightsServiceShim } from './app-insights.shim';

@Injectable()
export class BrowserAppInsightsShim extends AppInsightsServiceShim {

  public constructor(private appInsights: AppInsightsService) {
    super();
  }

  public setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string): void {
    this.appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId);
  }
  public clearAuthenticatedUserContext(): void {
    this.appInsights.clearAuthenticatedUserContext();
  }

  public trackEvent(
    name: string,
    properties?: { [name: string]: string; },
    measurements?: { [name: string]: number; }
  ): void {
    this.appInsights.trackEvent(name, properties, measurements);
  }
}
