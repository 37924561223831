// This is the logic for the left sidebar animations, using animation builder instead of DSL (Domain specific
// language). These functions return AnimationPlayer instances, which can be used to run the animations on the
// ElementRef, that was passed in as the second parameter.

// The standard animation DSL (the array in the @Component decorator and the @trigger syntax in the templates)
// has some bugs with the same animation using query(animateChild) on itself and with using that on grandchild
// components.  The programatic aproach does not block child animations, which does mean children must have
// their exit animations timed properly to match this, or at least close, but that allows it to work in this
// case while DSL would not work (believe me, I tried!!!).

import { animate, AnimationBuilder, AnimationPlayer, sequence, style } from '@angular/animations';
import { ElementRef } from '@angular/core';

import { SizeDependentState } from './page.models';

const leftBodyStyle = {
  marginLeft: '300px'
};
const noneBodyStyle = {
  marginLeft: 0
};
const contentBodyStyle = {
  marginLeft: 0
};

export function buildLeftBodyAnimations(
  builder: AnimationBuilder,
  bodyRef: ElementRef,
  oldState: SizeDependentState,
  newState: SizeDependentState,
  isFirstRun: boolean = false
): AnimationPlayer {
  if (!bodyRef) {
    return null;
  }

  const newSetting = newState.bodyAnimation.leftSidebar;
  const oldSetting = oldState ? oldState.bodyAnimation.leftSidebar : 'void';

  const applyDefault = (): AnimationPlayer => {
    let stateStyle = {};
    switch (newSetting) {
      case 'content':
        stateStyle = contentBodyStyle;
        break;
      case 'left':
        stateStyle = leftBodyStyle;
        break;
      case 'none':
      case 'null':
      default:
        stateStyle = noneBodyStyle;
        break;
    }
    return builder
      .build([style(stateStyle)])
      .create(bodyRef.nativeElement, {});
  };

  if (isFirstRun || newSetting === oldSetting) {
    //just apply the style
    return applyDefault();
  } else {
    switch (oldSetting) {
      case 'content':
        switch (newSetting) {
          case 'left':
            return builder
              .build([
                style({ marginLeft: '100%' }),
                animate(
                  '550ms cubic-bezier(0, 0, 0.2, 1)',
                  style(leftBodyStyle)
                )
              ])
              .create(bodyRef.nativeElement, {});
        }
        break;
      case 'left':
        switch (newSetting) {
          case 'content':
            return builder
              .build([
                style(leftBodyStyle),
                animate(
                  '350ms cubic-bezier(0.4, 0, 1, 1)',
                  style({ marginLeft: '100%' })
                ),
                style(contentBodyStyle)
              ])
              .create(bodyRef.nativeElement, {});
          case 'none':
            return builder
              .build([
                style(leftBodyStyle),
                animate(
                  '250ms cubic-bezier(0.4, 0, 1, 1)',
                  style(noneBodyStyle)
                )
              ])
              .create(bodyRef.nativeElement, {});
        }
        break;
      case 'none':
        switch (newSetting) {
          case 'left':
            return builder
              .build([
                style(noneBodyStyle),
                animate(
                  '350ms cubic-bezier(0, 0, 0.2, 1)',
                  style(leftBodyStyle)
                )
              ])
              .create(bodyRef.nativeElement, {});
        }
        break;
    }
    //if not transition animation
    return applyDefault();
  }
}

const baseContentStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  width: '301px',
  transform: 'none'
};

const leftContentStyle = {
  ...baseContentStyle
};
const noneContentStyle = {
  ...baseContentStyle,
  transform: 'translateX(-301px)'
};
const contentContentStyle = {
  ...baseContentStyle,
  position: 'static'
};

export function buildLeftContentAnimations(
  builder: AnimationBuilder,
  contentRef: ElementRef,
  oldState: SizeDependentState,
  newState: SizeDependentState,
  isFirstRun: boolean = false
): AnimationPlayer {
  if (!contentRef) {
    return null;
  }

  const newSetting = newState.contentAnimation.leftSidebar;
  const oldSetting = oldState
    ? oldState.contentAnimation.leftSidebar
    : 'void';

  const applyDefault = (): AnimationPlayer => {
    let stateStyle = {};
    switch (newSetting) {
      case 'left':
        stateStyle = leftContentStyle;
        break;
      case 'none':
        stateStyle = noneContentStyle;
        break;

      case 'content':
        stateStyle = { width: '100%' };
        break;

      case 'null':
      default:
        stateStyle = '*';
        break;
    }
    return builder
      .build([style(stateStyle)])
      .create(contentRef.nativeElement, {});
  };

  if (isFirstRun || newSetting === oldSetting) {
    //just apply the style
    return applyDefault();
  } else {
    switch (oldSetting) {
      case 'content':
        switch (newSetting) {
          case 'left':
            return builder
              .build([
                style({
                  ...baseContentStyle,
                  width: '100%'
                }),
                animate('550ms cubic-bezier(0, 0, 0.2, 1)', style(leftContentStyle))
              ])
              .create(contentRef.nativeElement, {});
        }
        break;
      case 'left':
        switch (newSetting) {
          case 'content':
            return builder
              .build([
                sequence([
                  style(leftContentStyle),
                  animate(
                    '350ms cubic-bezier(0.4, 0, 1, 1)',
                    style({
                      width: '100%'
                    })
                  )
                ])
                //style(contentStyle)
              ])
              .create(contentRef.nativeElement, {});
          case 'none':
            return builder
              .build([
                style(leftContentStyle),
                animate('250ms cubic-bezier(0.4, 0, 1, 1)', style(noneContentStyle))
              ])
              .create(contentRef.nativeElement, {});
        }
        break;
      case 'none':
        switch (newSetting) {
          case 'left':
            return builder
              .build([
                style(noneContentStyle),
                animate('350ms cubic-bezier(0, 0, 0.2, 1)', style(leftContentStyle))
              ])
              .create(contentRef.nativeElement, {});
        }
        break;
    }
    //if not transition animation
    return applyDefault();
  }
}
