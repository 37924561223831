import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ForbiddenComponent } from './containers/forbidden/forbidden.component';
import { LoggedOutComponent } from './containers/loggedout/loggedout.component';
import { NotFoundComponent } from './containers/not-found/not-found.component';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AppServerAuthService } from './services/app-server-auth.service';

@NgModule({
  imports: [SharedModule, RouterModule],
  exports: [
    FooterComponent,
    HeaderComponent,
    ForbiddenComponent,
    LoggedOutComponent,
    NotFoundComponent
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    ForbiddenComponent,
    LoggedOutComponent,
    NotFoundComponent
  ],
  providers: [
    AppServerAuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: startup,
      multi: true,
      deps: [AppServerAuthService]
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export function startup(authService: AppServerAuthService): () => Promise<any> {
  return (): Promise<void> => {
    return authService.getUserInfo();
  };
}
