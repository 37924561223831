<mat-toolbar class="dmc-header">
  <h1 i18n>Service Offering Framework</h1>
</mat-toolbar>

<div class="card-container">
  <mat-card class="logged-out-card">
    <mat-card-title i18n>You Are Logged Out</mat-card-title>
    <mat-card-content i18n>You are now logged out.  Please log back in to access this tool.</mat-card-content>
    <mat-card-content fxLayoutAlign="end end">
      <button mat-button color="primary" (click)="login()" i18n>Login</button>
    </mat-card-content>
  </mat-card>
</div>
