import { animate, query, stagger, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { LinkProps } from '@app/functions/models/link-props';

@Component({
  selector: 'app-data-list',
  templateUrl: './data-list.component.html',
  styleUrls: ['./data-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('listAnimation', [
      transition(':enter', []),
      transition(':increment', [
        query(':enter', [
          style({opacity: 0}),
          stagger(25, [
            animate('100ms cubic-bezier(0, 0, 0.2, 1)', style({opacity: 1}))
          ])
        ], {optional: true})
      ])
    ])
  ]
})
export class DataListComponent {

  @Input()
  public links: LinkProps;

  public trackByFn(index: number, offering: LinkProps): any {
    return offering.href;
  }

}
