<mat-toolbar class="dmc-header">
  <h1 i18n>Service Offering Framework</h1>
</mat-toolbar>

<div class="card-container">
  <mat-card class="logged-out-card">
    <mat-card-title>You don't have access to this resource.</mat-card-title>
    <mat-card-content i18n>Please login to access this resource. </mat-card-content>
    <mat-card-content fxLayoutAlign="end end">
      <button mat-button color="primary" (click)="login()" i18n>Login</button>
    </mat-card-content>
  </mat-card>
</div>