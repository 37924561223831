import { Injectable } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  constructor(
    private media: MediaObserver
  ) { }

  //All of these must end with shareReplay(1) because media$
  //is a cold obserable, which can cause stack size issues for
  //complex state+media chains.
  //
  //shareReplay makes the output of these observable hot.

  public readonly isLtMd$ = this.media.media$.pipe(
    map((m) => {
      switch (m.mqAlias) {
        case 'xs':
        case 'sm':
          return true;
        default:
          return false;
      }
    }),
    startWith(this.media.isActive('xs') || this.media.isActive('sm')),
    shareReplay(1)
  );

  public readonly isXS$ = this.media.media$.pipe(
    map((m) => m.mqAlias === 'xs'),
    startWith(this.media.isActive('xs')),
    shareReplay(1)
  );

  public readonly isSM$ = this.media.media$.pipe(
    map((m) => m.mqAlias === 'sm'),
    startWith(this.media.isActive('sm')),
    shareReplay(1)
  );

  public readonly isGtMd$ = this.media.media$.pipe(
    map((m) => {
      switch (m.mqAlias) {
        case 'lg':
        case 'xl':
          return true;
        default: return false;
      }
    }),
    startWith(this.media.isActive('lg') || this.media.isActive('xl')),
    shareReplay(1)
  );

  public readonly displayMode$ = this.media.media$.pipe(
    map((m) => m.mqAlias),
    startWith(this.startMedia()),
    map((m) => {
      switch (m) {
        case 'xs': return 'mobile';
        case 'sm': return 'tablet';
        default: return 'desktop';
      }
    }),
    distinctUntilChanged(),
    shareReplay(1)
  );

  private startMedia(): string {
    if (this.media.isActive('xs')) {
      return 'xs';
    } else if (this.media.isActive('sm')) {
      return 'sm';
    } else {
      return 'md';
    }
  }
}
