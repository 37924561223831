import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-error-body',
  templateUrl: './error-body.component.html',
  styleUrls: ['./error-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorBodyComponent {

  @HostBinding('class.mat-body')
  public readonly classBody = true;

}
