export type Role = 'none' | 'left' | 'center' | 'right';

export interface Modifier {
  isBehind: boolean;
  pullRight: boolean;
}

export interface CoreState {
  readonly role: Role;
  readonly directChildRoles: Role[];
  readonly childRoles: Role[];

  readonly childAbove: boolean;
  readonly isTop: boolean;
}

export interface SizeDependentState {
  readonly displayContent: boolean;
  readonly displayChildren: boolean;
  readonly isPrimary: boolean;

  readonly childrenAnimation: {
    readonly mobile: string;
    readonly rightSidebar: string;
  };
  readonly bodyAnimation: {
    readonly rightSidebar: string;
    readonly leftSidebar: string;
  };
  readonly contentAnimation: {
    readonly mobile: string;
    readonly leftSidebar: string;
  };
}

function computeModifiers(modifiers: Modifier[]): Modifier {
  return modifiers.reduce(
    (acc, mod) => ({
      isBehind: acc.isBehind || mod.isBehind,
      pullRight: acc.pullRight || mod.pullRight
    }), {
      isBehind: false,
      pullRight: false
    });
}

export function computeCoreState(childStates: CoreState[], modifiers: Modifier): CoreState {
  //const modSettings = computeModifiers(modifiers);
  const { directChildRoles, childRoles, childAbove } =
    childStates.reduce(
      (acc: any, child) => ({
        directChildRoles: [...acc.directChildRoles, child.role],
        childRoles: [...acc.childRoles, ...child.childRoles, child.role],
        childAbove: acc.childAbove || child.childAbove || child.isTop
      }), {
        directChildRoles: [],
        childRoles: [],
        childAbove: false
      }
    );

  const role = ((cRoles, pullRight) => {
    if (pullRight) {
      return (cRoles.includes('right') ? 'none' : 'right') as Role;
    } else {
      if (cRoles.includes('center')) {
        if (cRoles.includes('left')) {
          return 'none' as Role;
        } else {
          return 'left' as Role;
        }
      } else {
        return 'center' as Role;
      }
    }
  })(childRoles, modifiers.pullRight);

  const isTop = childAbove ? false : !modifiers.isBehind;

  return {
    role,
    isTop,
    childRoles,
    directChildRoles,
    childAbove
  };
}

export function computeBreakpointState(
  debugKey: string,
  media: 'mobile' | 'tablet' | 'desktop' | string,
  coreState: CoreState
): SizeDependentState {
  switch (media) {
    case 'mobile':
      return {
        isPrimary: coreState.isTop,
        displayContent: coreState.isTop,
        displayChildren: coreState.childAbove,

        childrenAnimation: {
          mobile: coreState.childAbove ? 'top' : 'below',
          rightSidebar: 'null'
        },
        bodyAnimation: {
          leftSidebar: 'null',
          rightSidebar: 'null'
        },
        contentAnimation: {
          leftSidebar: 'null',
          mobile: coreState.isTop ? 'top' : 'below'
        }
      };
    case 'tablet':
      return {
        isPrimary: coreState.childAbove ? coreState.role === 'center' : coreState.role !== 'right',
        displayContent: !coreState.childAbove ||
          coreState.role === 'center' ||
          coreState.role === 'right',
        displayChildren: coreState.childAbove,

        childrenAnimation: {
          mobile: coreState.childAbove ?
            (coreState.directChildRoles.includes('right') ? 'right' : 'top') :
            'below',
          rightSidebar: coreState.directChildRoles.includes('right') ? 'show' : 'hide'
        },
        bodyAnimation: {
          leftSidebar: 'null',
          rightSidebar: coreState.directChildRoles.includes('right') ? 'show' : 'hide'
        },
        contentAnimation: {
          mobile: coreState.isTop ? 'top' :
            (coreState.directChildRoles.includes('right') ? 'right' : 'below'),
          leftSidebar: 'null'
        }
      };
    default:
      return {
        isPrimary: coreState.role === 'center',
        displayContent: coreState.role !== 'none',
        displayChildren: coreState.directChildRoles.length > 0,

        childrenAnimation: {
          mobile: 'null',
          rightSidebar: coreState.directChildRoles.includes('right') ? 'show' : 'hide'
        },
        bodyAnimation: {
          leftSidebar: coreState.role === 'left' ? 'left' :
            (coreState.role === 'center' ? 'content' :
              (coreState.role === 'none' ? 'none' : 'null')
            ),
          rightSidebar: coreState.directChildRoles.includes('right') ? 'show' : 'hide'
        },
        contentAnimation: {
          mobile: 'null',
          leftSidebar: coreState.role === 'left' ? 'left' :
            (coreState.role === 'center' ? 'content' :
              (coreState.role === 'none' ? 'none' : 'null')
            )
        }
      };
  }
}
