<div class="body" #body [@bodyRight]="breakpointState.bodyAnimation.rightSidebar"
  [class.left]="coreState.role === 'left'" [class.right]="coreState.directChildRoles.includes('right')">

  <div class="content" #content [@contentMobile]="breakpointState.contentAnimation.mobile">
    <ng-container *ngIf="breakpointState?.displayContent">
      <ng-content></ng-content>
      <ng-template [ngTemplateOutlet]="conditionalChild?.templateRef"></ng-template>
    </ng-container>
  </div>

  <div class="children" #children [class.hidden]="!breakpointState.displayChildren"
    [@childrenRight]="breakpointState.childrenAnimation.rightSidebar"
    [@childrenMobile]="breakpointState.childrenAnimation.mobile">
    <router-outlet #outlet="outlet"></router-outlet>
  </div>

</div>